<script setup lang="ts">
import dayjs from "dayjs";

const now = dayjs().format("YYYY-MM-DDTHH:mm:ss");
const { data: events, pending } = await useAsyncData(() =>
  useDirectus(
    readItems("events", {
      fields: ["*", { event_participants: ["*"] }],
      filter: {
        start: { _gte: now },
      },
      sort: "start",

      limit: 4,
    })
  )
);
</script>

<template>
  <section class="relative bg-gray">
    <img src="/temp-events-bg.jpg" class="absolute hidden h-full w-full object-cover grayscale lg:block" />
    <div class="absolute inset-0 bg-primary opacity-80"></div>
    <CommonContainer class="relative z-20 py-[--y-margin]">
      <div class="mb-16 flex flex-wrap items-center justify-between gap-5 text-white">
        <div>
          <p class="text-center font-script text-3xl font-bold lg:text-left">How we reach people</p>
          <h2 class="text-center text-5xl font-semibold lg:text-left">Explore our events</h2>
        </div>
        <CommonButtonNuxtLink class="mx-auto md:mx-px" btn-style="secondary" btn-hover-style="tertiary" :to="{ path: '/events' }">
          More events
        </CommonButtonNuxtLink>
      </div>
      <font-awesome-icon v-if="pending" class="text-primary" icon="fa-solid fa-spinner" size="2xl" spin-pulse></font-awesome-icon>
      <div v-else>
        <EventList v-if="events?.length" :events="events"></EventList>
        <div v-else class="text-center font-script text-xl text-white md:text-2xl xl:text-4xl">No upcoming events</div>
      </div>
    </CommonContainer>
  </section>
</template>
